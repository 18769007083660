@font-face {
  font-family: "halloicons";
  src: url("fonts/halloicons.ttf") format("truetype"),
    url("fonts/halloicons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "halloicons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-conversation-icon:before {
  content: "\e92d";
}
.icon-results-icon:before {
  content: "\e92e";
}
.icon-download-icon:before {
  content: "\e92f";
}
.icon-options-icon:before {
  content: "\e930";
}
.icon-open-menu:before {
  content: "\e926";
}
.icon-ic_heart_active:before {
  content: "\e925";
}
.icon-video-call:before {
  content: "\e921";
}
.icon-marker:before {
  content: "\e922";
}
.icon-pie-chart:before {
  content: "\e908";
}
.icon-hourglass:before {
  content: "\e906";
}
.icon-followers:before {
  content: "\e907";
}
.icon-ic_mensagem:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e927";
}
.icon-ic_imagem:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e920";
}
.icon-share:before {
  content: "\e91f";
}
.icon-back:before {
  content: "\e91e";
}
.icon-tickets:before {
  content: "\e91c";
}
.icon-next:before {
  content: "\e91d";
}
.icon-check:before {
  content: "\e91b";
}
.icon-photo-camera:before {
  content: "\e91a";
}

.icon-bookmark-empty:before {
  content: "\e911";
}
.icon-bookmark:before {
  content: "\e919";
}

.icon-events:before {
  content: "\e90e";
}
.icon-wall-clock:before {
  content: "\e928";
}
.icon-eye:before {
  content: "\e90c";
}
.icon-ic_meus_anuncios-1:before {
  content: "\e90a";
}
.icon-ic_perfil1-2:before {
  content: "\e90b";
}
.icon-star-2:before {
  content: "\e900";
}
.icon-ic_mensagens1:before {
  content: "\e902";
}
.icon-ic_voucher .path1:before {
  content: "\e903";
}
.icon-ic_voucher .path2:before {
  content: "\e904";
  margin-left: -1em;
}
.icon-ic_voucher .path3:before {
  content: "\e905";
  margin-left: -1em;
}
.icon-ic_voucher .path4:before {
  content: "\e906";
  margin-left: -1em;
}
.icon-ic_voucher .path5:before {
  content: "\e907";
  margin-left: -1em;
}
.icon-ic_voucher .path6:before {
  content: "\e908";
  margin-left: -1em;
}
.icon-ic_notificacao:before {
  content: "\e90d";
}
.icon-ic_minhas_compras:before {
  content: "\e90f";
}
.icon-store:before {
  content: "\e910";
}
.icon-ic_heart:before {
  content: "\e912";
}
.icon-ic_configuracoes:before {
  content: "\e913";
}
.icon-ic_twitter:before {
  content: "\e914";
}
.icon-ic_linkedin:before {
  content: "\e915";
}
.icon-ic_instagram:before {
  content: "\e916";
}
.icon-ic_facebook:before {
  content: "\e917";
}
.icon-star:before {
  content: "\e918";
}
.icon-delete:before {
  content: "\e901";
}
.icon-edit:before {
  content: "\e909";
}

.icon-up:before {
  content: "\e92b";
  display: inline-block;
  transition: transform 1s ease-out;
}
.icon-up.close:before {
  transform: rotateX(180deg);
  transition: transform 1s ease-out;
}
.icon-down:before {
  content: "\e92c";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-enlarge:before {
  content: "\e989";
}
