@import "src/customer";

div.container-success {
  display: grid;
  place-items: center;

  width: 100%;
  height: 80vh;
}

div.success-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 500px;
  width: 100%;
  height: 400px;
  background-color: #fff;
  border-radius: 8px;

  box-shadow: 0px 2rem 0px var(--secondary_color);

  border-top-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;

  img {
    height: 50%;
    width: 100%;
  }

  h3 {
    text-align: center;

    margin-top: 4rem;

    font-size: 2.5rem;
  }

  p {
    text-align: center;

    margin-top: 1rem;

    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  div.success {
    width: 320px;

    div.success-content {
      img {
        height: 120px;
      }

      h3 {
        font-size: 20px;
        margin-top: 60px;
      }
    }
  }
}
