@import "src/customer";

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  .modal-card {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    max-width: 710px;
    background: #fff;
    border-radius: 0 4rem 4rem 4rem;
    box-shadow: 0px 2rem 0px var(--boxshadow_color);
    z-index: 999;
  }
}
@media (max-width: 720px) {
  .modal-backdrop {
    .modal-card {
      width: 90%;
    }
  }
} ;
