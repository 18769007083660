@keyframes animateShowReplyMessage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.hidden {
  display: none !important;
}

.show-animation {
  animation-name: animateShowReplyMessage;
  animation-duration: 250ms;
}

.reply-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;

  background: #eeeeee;

  padding: 0.5rem 2rem;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  @media only screen and (max-width: 720px) {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    order: 1;
  }

  &__content {
    font-size: 14px;
    text-overflow: ellipsis;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    max-width: 90%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 15px;
      height: 15px;
    }
  }
  .player {
    width: 90%;
  }
}
