div.container-checkbox {
  display: flex;
  align-items: center;

  > input {
    display: block !important;

    width: auto;
  }

  > label {
    margin-left: 5px;
  }
}

div.error {
  margin-top: 5px;

  color: #e1173f;
}
