.container-li-comment {
  display: flex;
  flex-shrink: 0;
  padding: 1rem;
  color: var(--secondary_text_color);
  flex-direction: column;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  @media only screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
  .marked-message {
    background: #eeeeee !important;
    border-radius: 5px;
    padding-left: 5px;
  }

  .container-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .reply-icon {
      width: 25px;
      height: 25px;
      margin-left: 3px;
    }
    .content-message {
      width: 100%;
      .message {
        word-break: break-word;
        font-size: 14px;
        height: 100%;
        line-height: 22px;

        @media only screen and (max-width: 720px) {
          font-size: 13px;
        }

        a {
          cursor: pointer;
          color: var(--primary_color);
        }
      }
    }
  }

  .comment-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 4px;
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .user-name {
      font-weight: 700;
      margin-right: 1rem;
      font-family: "Exo 2", sans-serif;
      font-size: 1.7rem;
      @media only screen and (max-width: 720px) {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
      }
    }
    .date {
      margin-left: auto;
      font-size: 1.4rem;
      color: #78849e;
      @media only screen and (max-width: 720px) {
        margin-left: unset;
      }
    }
  }

  .replied-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0.5rem 0 0.5rem 1rem;

    background: #eeeeee;
    border-radius: 10px;
    padding: 0.8rem;

    p {
      font-size: 14px;

      &.receiver {
        margin-bottom: 5px;
      }
      @media only screen and (max-width: 720px) {
        font-size: 13px;
      }
    }

    div.player {
      width: 100%;
    }

    p.replying-to {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 15px;
      max-height: 30px;
      max-width: 80%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.pinned-message {
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px 3px 11px #00000029;
  background-color: #fff;
  padding: 1rem;
  color: var(--secondary_text_color);
  word-break: break-word;
  max-height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-track {
    background: #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 20px;
    border: 2px solid #fafafa;
  }
  // flex: 1;
  // @media only screen and(max-width:720px) {
  //   order: 1;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }
  .pinned-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 0.6rem;
  }

  @media only screen and(max-width:720px) {
    // flex-direction: column;
    // align-items: flex-start;
    font-size: 12px;
  }
  span {
    font-size: 12px;
  }
  h1 {
    margin: 0.2rem 0;
    font-size: 16px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    @media only screen and(max-width:720px) {
      font-size: 14px;
      order: 1;
    }
  }

  .pinned-message-text {
    font-size: 12px;

    &-button {
      padding: 0.2rem;
      color: var(--secondary_text_color);
      font-weight: bold;
    }
  }
}
