div.container-input,
div.container-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  &.with-error {
    > input,
    .css-yk16xz-control {
      border-color: #e1173f;
    }
  }

  > label {
    margin-bottom: 10px;
  }

  > input {
    min-height: 30px;

    padding: 5px 8px;

    border: 1px solid transparent;

    border-radius: 8px;

    background: #edf2f7;
  }

  div.error {
    margin-top: 5px;

    color: #e1173f;
  }
}
