@import "src/customer";

div.upload-input {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  input {
    display: none;
  }

  label {
    position: relative;
    cursor: pointer;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    margin: 0;
    width: 100px;
    height: 100px;
    background-color: #ccc;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  div.upload-input-container {
    display: flex;
    flex-direction: column;

    align-items: center;

    > span.error-message {
      margin-top: 10px;

      color: #e1173f;
    }
  }
}

div.upload-input-remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000033;
  z-index: 1;
  cursor: pointer;

  div {
    font-size: 10px;
  }
}

div.upload-input-overflow {
  opacity: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.5s;
  font-size: 30px;
  color: #fff;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

div.wrapper {
  display: grid;
  place-items: center;

  width: auto;

  padding: 20px;

  background-color: var(--background_color);
}

div.form-wrapper {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  margin: 20px 0;

  margin-bottom: 2rem;

  box-shadow: 0px 2rem 0px var(--secondary_color);

  border-top-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;

  form {
    padding: 32px;
    display: flex;
    flex-direction: column;

    font-size: clamp(14px, 1.5rem, 18px);

    > div + div {
      margin-top: 15px;
    }

    button {
      display: grid;
      place-items: center;

      max-height: 50px;

      align-self: center;

      margin-top: 20px;
      padding: 12px 24px;

      border-color: var(--primary_color);
      background-color: var(--primary_color);

      border-radius: 10px;
      color: #fff;

      text-align: center;
      font-weight: 500;

      > div.loader {
        margin: 0;
      }
    }

    p {
      font-size: 12px;
    }

    h6 {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .form {
    width: 320px;
  }
}
