
.navbar-logo-img {
  img {
    height: 65px;
    @media (max-width: 600px) {
      height: 10vw;
    }
  }
}


.container-fluid-home,
.container-fluid-livestream {
  background-image: var(--background_image);
  background-size: 80vw;
}