:root {
  --primary_color: #082045;
  --secondary_color: #41ffcb;
  --prepoll_color: #41ffcb;
  --background_color: #082045;
  --winner_color: #02812c;
  --primary_text_color: #111111;
  --secondary_text_color: #111111;
  --boxshadow_color: #02122a;
  --formal_consultation_color: #e9fff9;
  --background_image: url("../assets/imgs/unicred/pattern.svg");
}
#meetingSDKElement {
  right: unset !important;
  left: 120px !important;
  z-index: 4;
}
#transition-popper{
  z-index: 5;
}
.MuiTypography-root,
.MuiButton-label {
  font-size: 12px !important;
}
.MuiInputBase-root {
  line-height: 12px !important;
}

.MuiTab-wrapper {
  font-size: 12px !important;
}

.MuiInputLabel-root {
  font-size: 12px !important;
}
