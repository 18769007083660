@import 'src/customer';

.account {
  padding: 30px 5vw;
  color: white;

  h1 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Exo 2', sans-serif;
  }
  .content {
    max-width: 70%;
    margin: auto;
    padding: 25px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 20px 0px var(--boxshadow_color);
  }
  .box {
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
  }
  .space {
    margin-bottom: 25px;

    label {
      cursor: pointer;
      display: block;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 15px;
      color: #2a2e43;
    }
    input {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      font-size: 14px;
      color: #454f63;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
.upload-input {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.terms {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  a {
    font-size: 14px;
    margin-left: 5px;
    font-weight: 600;
    color: var(--primary_color);

    &:hover {
      text-decoration: underline;
    }
  }
}
.actions {
  display: flex;
  justify-content: center;
}
.upload {
  position: relative;
  cursor: pointer;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin: 0;
  width: 100px;
  height: 100px;
  background-color: #ccc;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .u-overflow {
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.0);
    transition: .5s;
    font-size: 30px;
    color: #fff;

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
@media only screen and (max-width: 425px) {
  .account {
    .content {
      max-width: 100%;
    }
  }
};