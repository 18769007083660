@import "src/customer";

.container-fluid-livestream {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  padding: 0 !important;
  background-repeat: no-repeat;
  background-color: var(--background_color);
  display: flex;
  flex-direction: column;
}

.suspense-screen-live {
  width: 100vw;
  height: 100vh;
  background: #fafafa;

  .suspense-navbar {
    width: 100%;
    height: 80px;
    background: #eeeeee;
    animation: loading-suspense 1s infinite;
    animation-timing-function: linear;
  }
  .suspense-container-live-comment {
    display: flex;
    margin: 50px 0;
  }
  .suspense-video {
    width: 60vw;
    height: 50vh;
    margin: 0 auto;
    background: #eeeeee;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    animation: loading-suspense 1s infinite;
    animation-timing-function: linear;
  }
  .suspense-comments {
    width: 30vw;
    height: 70vh;
    margin: 0 auto;
    background: #eeeeee;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    animation: loading-suspense 1s infinite;
    animation-timing-function: linear;

    @keyframes loading-suspense {
      0% {
        background-color: #eeeeee;
      }
      25% {
        background-color: #eeeeeec4;
      }
      50% {
        background-color: #eeeeee1c;
      }
      75% {
        background-color: #eeeeeec4;
      }
      100% {
        background-color: #eeeeee;
      }
    }
  }
}
.container-live-video-full {
  height: 100% !important;
}

.container-live {
  width: 100%;
  display: flex;
  gap: 3%;
  position: relative;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  .container-title-documents {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    background-color: #f7f7fa;
    padding: 1rem 3vw;
    align-items: center;
    gap: 2rem;
    .container-title {
      color: var(--primary_text_color);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      > h1 {
        font-size: 2.2rem;
        font-weight: 800;
        font-family: "Exo 2", sans-serif;
      }
      .time-display {
        margin: 0 2rem;
        font-size: 1.6rem;
        white-space: nowrap;
        @media (max-width: 720px) {
          margin-top: 1rem;
        }
      }
    }
  }
  .container-live-content {
    display: flex;
    padding: 1rem 3vw 5rem;
    width: 100vw;
    position: relative;
    flex-grow: 1;
  }
  .review-container-wrap {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 1;
    backdrop-filter: blur(5px);
  }
  .zoom-embbeded-container {
    background: black;
    width: 100%;
    display: flex;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 20px 0 1px rgba(0, 0, 0, 0.2);
  }
  .zoom-poll {
    height: 40vh;
  }
  .container-live-video {
    flex-grow: 1;
    width: 70%;
    position: relative;
    grid-area: video;
    z-index: 1;
    .live-video-player-wrapper {
      width: 100%;
      display: flex;
      height: 45vh;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      box-shadow: 0 20px 0 1px rgba(0, 0, 0, 0.2);
      background-color: #000;
      .image-player-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        @media only screen and (max-width: 720px) {
          height: 30vh;
        }
        .image-player {
          position: absolute;
          object-fit: cover;
          height: auto;
          max-height: 100%;
          max-width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .with-polls {
    display: grid;
    grid-template-areas:
      "video forum"
      "poll forum";
    grid-template-rows: 45vh 1fr;
    grid-template-columns: 70% 1fr;
    gap: 3rem;
    // place-items: center;
    @media (max-width: 1080px) {
      gap: 0;
      .box-poll {
        margin: 2rem 0 6rem;
      }
    }
    .container-live-video {
      width: 100%;
      min-height: unset;
      margin-bottom: 0;
    }
    .container-comments {
      width: 100%;
      margin: 0;
      @media (max-width: 720px) {
        margin-top: 2rem;
      }
    }
  }
}

.full-label,
.mobile-label {
  position: relative;

  &::after {
    content: attr(data-content);

    position: absolute;
    top: -20%;
    left: 90%;

    display: grid;
    place-items: center;

    width: 22px;
    height: 22px;

    background: #cc0000;
    border-radius: 50%;

    color: #fff;
    font-size: 14px;
    font-weight: 600;

    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
  }
}

.mobile-label::after {
  top: -15%;
  left: 42%;
}

.hidden-budge::after {
  visibility: hidden;
}

.container-button-vote-mobile {
  display: none;
}
.box-poll {
  min-width: 100%;
  display: flex;
  position: relative;
  grid-area: poll;
  .icon-close {
    display: none;
  }
  .poll-container {
    color: white;
    width: 170px;
    min-height: 170px;
    display: flex;
    background: var(--secondary_color);
    align-items: center;
    justify-content: center;
    box-shadow: 0px 21px 0px 0px var(--secondary_color);
    padding: 2rem 2.5rem;

    .mobile-title {
      font-size: 2.2rem;
      display: none;
    }
    .title-mobile {
      display: none;
      font-size: 2.2rem;
    }
    .number-of-votes-mobile {
      display: none;
    }
    .icon-results-icon {
      color: white;
      font-size: 5rem;
    }
    .icon-options-icon {
      &::before {
        font-size: 2.5rem;
      }
    }
  }
  .infos-poll {
    width: 100%;
    display: flex;
    gap: 1.5rem 0px;
    padding: 2rem 2.5rem;
    background: white;
    position: relative;
    color: var(--primary_text_color);
    flex-direction: column;
    justify-content: center;
    border-bottom-right-radius: 40px;
    box-shadow: 0px 20px 0px 1px var(--secondary_color);
    grid-area: poll;

    .alert-full-width {
      font-size: 1.8rem;
    }
    .poll-title {
      font-size: 2.2rem;
      display: none;
    }
    button {
      width: 300px;
      margin: 1rem auto;

      .loader {
        margin: 5px auto;
      }
    }
    .title-poll-container-static {
      font-size: 4rem;
      font-weight: 800;
      font-family: "Exo 2", sans-serif;
    }
    .title-poll {
      font-size: 2.4rem;
    }
    .title-result {
      font-size: 2rem;
      font-weight: 600;
      padding: 1rem;
      order: 1;
    }
    .multiple-choice {
      font-size: 1.8rem;
      color: var(--secondary_text_color);
      font-weight: bold;
    }
    .title-result-attachment img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-top: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
    .container-question {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem 0;
      > label {
        margin-right: 2rem;
      }
      p {
        font-size: 2rem;
      }
    }
    .next-prev-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      order: 3;
      color: var(--secondary_color);
      font-weight: bold;
      font-size: 2.1rem;
      .container-button-next-previous {
        user-select: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon-next {
          padding-left: 1rem;
        }
        .icon-back {
          padding-right: 1rem;
        }
        > span {
          cursor: pointer;
        }
      }
      .next-prev-button {
        min-width: auto;
        border-color: var(--secondary_color);
        padding: 1rem 1.6rem;
        width: auto;
        background-color: var(--secondary_color);
      }
    }
    .body-results {
      width: 100%;
      display: flex;
      margin: 0 auto;
      order: 2;
    }
    .container-agency {
      width: 100%;
      .result-title-agency {
        font-size: 1.6rem;
        font-weight: 600;
      }
      .container-group {
        display: flex;
        margin-top: 0.8rem;
        flex-direction: column;
        .result-title-group {
          font-size: 1.6rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
      }
      .container-results-poll {
        width: 85%;
        margin-top: 0.8rem;
        .result-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2rem;
          align-items: center;
          color: var(--secondary_text_color);
          .result-votes-amount-container {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 30%;
            justify-content: space-between;
            margin-left: 1.2rem;
            font-size: 1.7rem;
          }
          p.winner {
            font-weight: 600;
            color: var(--winner_color) !important;
          }
          p {
            font-size: 1.7rem;
            font-weight: 400;
            text-align: center;
            margin-left: 0.5rem;
            white-space: nowrap;
          }
          .percentage-option-results,
          .title-option-results {
            font-weight: 600;
            text-align: left;
            white-space: unset;
            text-align: justify;
          }

          .title-option-results {
            span {
              display: flex;
              align-items: center;

              img {
                width: 70px;
                height: 70px;
                object-fit: cover;
                margin-right: 10px;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }
        }
        .total {
          margin-top: 1.5rem;
        }
      }
    }
  }
}
.mobile-label {
  display: none;
}
.finished-live {
  top: -10px;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 2000;
  position: absolute;
  align-items: center;
  justify-content: center;

  p {
    color: white;
    padding: 1rem 4rem;
    background: #f44336;
    border-radius: 20px;
  }
}
.modal-companies-content {
  background: white;

  .modal-companies-error {
    margin-top: 10px;
    color: #d12929;
  }

  .modal-companies-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    text-align: center;
  }
  h1 {
    line-height: 1.3;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    strong {
      display: block;
      font-weight: 600;
    }
  }
  p {
    font-size: 1.5rem;
  }
}
.modal-companies-actions {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.modal-companies-list {
  li {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1080px) {
  .container-live {
    .container-title-documents {
      height: auto;
      .container-title {
        > h1 {
          width: 100%;
          text-align: justify;
        }
      }
      .full-label {
        display: none;
      }
      .mobile-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.6rem;
        color: var(--primary_text_color);
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }
    .container-live-content {
      flex-direction: column;
      display: flex;
      .review-container-wrap {
        z-index: 2000;
        position: fixed;
        height: 100%;

        &.fixed {
          position: fixed;
          top: 0;
          z-index: 1000;
        }
      }
      .container-live-video {
        width: 100%;
        min-height: unset;
        height: auto;
        margin-bottom: 4rem;
        .live-video-player-wrapper {
          border-radius: 0 20px 20px 20px;
          height: auto;
          .video-react {
            height: auto !important;
            padding-top: 56.25% !important;
            position: relative;
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .container-live {
    padding-bottom: 80px;

    .container-title-documents {
      min-height: 60px;

      .container-title {
        flex-direction: column;
        flex-grow: 1;
        gap: 0.5rem;
      }
    }
    .container-live-content {
      padding: 0 1rem;
      width: 100%;

      .review-container-wrap {
        height: 56.25vw;
        border-radius: 0 20px 20px 20px;
        &.fixed {
          top: 0;
          z-index: 1000;
        }
      }
      .container-live-video {
        margin: 0 auto 3rem;
        position: sticky;
        top: 2px;

        .live-video-player-wrapper {
          box-shadow: 0 10px 0 1px rgba(0, 0, 0, 0.1);
          border-radius: 0 0 20px 20px;
          height: auto;
          .video-react {
            height: auto !important;
            padding-top: 56.25% !important;
            position: relative;
          }
        }
      }
      .container-live-video-full {
        height: auto !important;
      }
    }
    .with-polls {
      .box-poll {
        margin: 1.6rem 1rem;
        min-width: unset;
        flex-direction: column;

        .title-poll-container-static {
          display: none;
        }
        .poll-container {
          width: 100%;
          height: auto;
          min-height: 30px;
          justify-content: flex-start;
          border-radius: 0 20px 0 0;

          .icon-results-icon {
            margin-right: 10px;
          }
          .title-mobile {
            display: unset;
          }
          .icon-options-icon {
            margin-right: 1.2rem;
          }
          .mobile-title {
            display: unset;
          }
        }
        .container-button-vote {
          display: none;
        }
        .container-button-vote-mobile {
          display: unset;
        }
        .infos-poll {
          box-shadow: unset;
          border-radius: 0 0 20px 20px;

          .info-title {
            order: 1;
          }
          .next-prev-container {
            order: 2;
          }
          .body-results {
            display: flex;
            order: 3;

            .container-agency {
              width: 100%;

              .container-group {
                flex-direction: column;
              }
            }
            .container-results-poll {
              width: 100%;
              .result-item {
                margin: 1rem 0;

                p {
                  font-weight: 300;
                  margin-left: 0;
                }
                .result-votes-amount-container {
                  display: flex;
                  flex-direction: column;
                  min-width: unset;
                }
                .percentage-option-results,
                .title-option-results {
                  font-weight: 600;
                }
              }
            }
          }
          .title-poll {
            display: none;
          }
          .poll-title,
          .title-result {
            display: unset;
            color: var(--primary_text_color);
            padding: 1rem 0.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            margin: 0;
          }
          .container-question {
            flex-direction: column;
          }
        }
      }
    }
  }
  .container-button-vote-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    button {
      border: 2px solid #ffffff;
      width: 90%;
      padding: 1rem;
      font-size: 16px;
      .loader {
        margin: 5px auto;
      }
    }
  }
}
.body-new-vote {
  .icon-close {
    top: 20px;
    right: 30px;
    cursor: pointer;
    position: absolute;
    color: var(--primary_color);
  }
  > h3 {
    font-size: 1.8rem;
    color: var(--primary_text_color);
  }
  > h4 {
    font-size: 1.6rem;
    margin: 2rem 0;
    color: var(--secondary_text_color);
  }
  > h3,
  > h4 {
    text-align: center;
    font-family: "Exo 2", sans-serif;
  }
  > b {
    color: var(--primary_color);
  }
  .buttons-modal-new-vote {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    @media only screen and (max-width: 720px) {
      flex-direction: column;
    }
  }
  .button-width {
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
}
.button-full-screen {
  display: flex;
  margin-left: auto;
  margin-right: 1vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > span {
    font-size: 1.6rem;
    @media only screen and (max-width: 720px) {
      padding: 1rem;
    }
  }
}

@media (max-width: 1080px) {
  .container-live-video-full {
    height: 40vh !important;
  }
}

@media only screen and (max-width: 425px) {
  .suspense-screen-live {
    .suspense-navbar {
      height: 50px;
    }
    .suspense-container-live-comment {
      flex-direction: column;
      margin: 20px 0;
    }
    .suspense-video {
      width: 90vw;
      height: 30vh;
    }
    .suspense-comments {
      width: 90vw;
      height: 40vh;
      margin: 50px auto;
    }
  }
}

.poll-voter {
  padding: 10px 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #ebebeb;
  font-size: 2.4rem;
  color: var(--primary_text_color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.poll-attachment img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.ballot-paper-button {
  position: absolute;
  bottom: 5px;
  right: 35%;
  button {
    &:disabled {
      cursor: progress;
    }

    img {
      width: 20px;
    }
  }
  @media only screen and (max-width: 1080px) {
    position: relative;
    right: 0;
    bottom: 10px;
    padding-right: 15px;
    text-align: right;
  }
  @media only screen and (max-width: 720px) {
    position: relative;
  }
}
