
.input {
  padding: 0 15px;
  border-radius: 10px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #454f63;
  border: none;
}
.line {
  height: 25px;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border-bottom: 1px solid #eaeaea;
}

.textera {
  padding: 19px 12px;
  overflow: hidden;
  outline: none;
  resize: none;

  ::-webkit-scrollbar {
    display: none;
  }
}