.container-comments {
  margin-left: 3rem;
  background: white;
  border-bottom-right-radius: 4rem;
  box-shadow: 0 20px 0 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  grid-area: forum;
  width: 30%;
  overflow: hidden;

  @media only screen and (max-width: 1080px) {
    width: 100%;
    margin: 0;
    height: 750px;
    margin-top: 6rem;
  }

  @media only screen and (max-width: 720px) {
    border-radius: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 600px;
    padding: 0 0 1rem;
    margin-top: 3rem;
  }

  .comments-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 720px) {
      flex-grow: 1;
    }
  }
  .loading-ul {
    height: calc(70vh - 195px);
    overflow: hidden;
  }
}
