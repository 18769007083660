@font-face {
  font-family: "Open Sans", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: url("font-family: 'Open Sans', sans-serif;");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@700;800&display=swap");

:root {
  --font-family: "Open Sans", sans-serif;
}

html {
  font-size: 62.5% !important;
  box-sizing: border-box;
  height: 100%;

  @media only screen and (max-width: 1080px) {
    font-size: 50% !important;
  }
  @media only screen and (max-width: 720px) {
    font-size: 43.5% !important;
  }
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  font-family: var(--font-family);

  overflow-y: visible !important;
  overflow-x: hidden !important;

  & > #zmmtg-root {
    display: none;
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: var(--font-family);
}

ul {
  list-style: none;
}

input,
textarea {
  border: 0;
  outline: 0;
  display: block;
  width: 100%;
  background-color: #f8f8f8;
  font-family: var(--font-family);
  font-size: 1.8rem;
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  font-family: var(--font-family);
}

.button-primary:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

table {
  thead {
    th {
      font-size: 14px;
    }
  }
}

.container,
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.modal {
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  z-index: 9999;
  min-height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background-color: rgba(25, 25, 25, 0.245);
}

.input-error {
  margin-top: 8px;
  font-size: 12px;
  color: #c40404;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

.suspension-window-container,
.suspension-video-container__container,
.suspension-window-container--show,
.suspension-window-container--show-1,
.react-draggable {
  display: inherit;
}

.infinite-scroll-component {
  -webkit-overflow-scrolling: auto !important;
}

// mux-player

mux-player {
  width: 100%;
  height: 100%;
}