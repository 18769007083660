@import "src/customer";

.container-fluid-home {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  padding: 0 !important;
  background-repeat: no-repeat;
  background-color: var(--background_color);

  > .container {
    margin-bottom: 4rem;
  }
}
.loading-container-live-box-list-mobile {
  width: 100%;
  height: 250px;
  background-color: #fafafa;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  animation: loading 1.5s infinite;
  animation-timing-function: linear;
}
.loading-container-live-box-list {
  width: 360px;
  height: 250px;
  margin: 20px;
  background-color: #fafafa;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  animation: loading 1.5s infinite;
  animation-timing-function: linear;
}
@keyframes loading {
  0% {
    background-color: #fafafa;
  }
  25% {
    background-color: #fafafaa1;
  }
  50% {
    background-color: #fafafa1a;
  }
  75% {
    background-color: #fafafaa1;
  }
  100% {
    background-color: #fafafa;
  }
}

.container-live-box-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 3vw 5vh;
  //justify-content: space-between

  .no-live-found {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 50vh;

    p {
      margin-bottom: 7px;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 720px) {
    .todays-livestreams {
      box-shadow: 0 3px 0 1px var(--secondary_color);
    }
    .pre-polls {
      box-shadow: 0 3px 0 1px var(--prepoll_color);
    }
    .pos-polls {
      box-shadow: 0 3px 0 1px #ffc400;
    }
  }
}
.container-live-box-list-mobile {
  display: flex;
  margin-top: 2.5rem;
  padding: 0px 3rem;
  flex-direction: column;
}
.title-home {
  width: 100%;
  color: white;
  margin-top: 5rem;

  > h2 {
    font-size: 3.2rem;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;

    span {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .icon-up,
      .icon-down {
        margin-left: 12px;
        margin-bottom: -7px;
        font-size: 15px;
      }
    }
  }
}
.suspense-screen-home {
  height: 100vh;
  width: 100vw;
  background: #fafafa;

  .navbar-suspense {
    width: 100%;
    height: 80px;
    background: #eeeeee;
    animation: loading-suspense 0.5s infinite;
    animation-timing-function: linear;
  }
  .title-suspense {
    width: 40vw;
    height: 40px;
    margin: 3vw;
    background: #eeeeee;
    animation: loading-suspense 0.5s infinite;
    animation-timing-function: linear;
  }
  .lives-list-suspense {
    width: 370px;
    height: 200px;
    margin: 3vw;
    background: #eeeeee;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    animation: loading-suspense 0.5s infinite;
    animation-timing-function: linear;
  }
  @keyframes loading-suspense {
    0% {
      background-color: #eeeeee;
    }
    25% {
      background-color: #eeeeeec4;
    }
    50% {
      background-color: #eeeeee7a;
    }
    75% {
      background-color: #eeeeeec4;
    }
    100% {
      background-color: #eeeeee;
    }
  }
}
.container-live-box {
  position: relative;
  max-width: 30%;
  margin: 5rem 2.5rem 0;
  width: 100%;
  flex-grow: 1;
  cursor: pointer;
  transition: 0.5s;
  background-color: var(--primary_color);
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
  box-shadow: 0px 10px 0px var(--boxshadow_color);
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 720px) {
    margin-left: 0;
    margin-right: 0;
    // display: none;
  }

  .container-live-box-preview {
    height: 175px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    border-top-right-radius: 4rem;
    @media (max-width: 330px) {
      display: none;
    }
    .logo-container {
      position: relative;
      display: flex;
      flex: 1;
      width: 100%;
      background-color: #fff;
      padding-bottom: 1.2rem;
      padding-top: 1.2rem;
      align-items: center;
      justify-content: center;
      img {
        width: 11rem;
        max-height: 100%;
        position: absolute;
      }
      .logo-white {
        display: none;
      }
    }
    .status-video {
      width: 100%;
      height: 45px;
      color: white;
      display: flex;
      padding: 1rem 0;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
    .todays-livestreams {
      background: var(--secondary_color);
      font-size: 1.8rem;
    }
    .pre-polls {
      background: var(--prepoll_color);
      font-size: 1.8rem;
    }
    .pos-polls {
      background: #ffc400;
      font-size: 1.8rem;
    }
  }
  .container-live-box-details {
    color: white;
    padding: 1rem 3rem 70px 3rem;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;

    h5 {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.3em;
    }
  }
  .container-live-box-details-info {
    height: 58px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    padding: 0 3rem 1em 3rem;

    p {
      margin-top: 1rem;
      font-size: 1.4rem;
      font-weight: 300;

      span {
        font-size: 1.4rem;
        margin-right: 1rem;
        font-weight: 300;
      }
    }
    .status-video-mobile {
      display: none;
    }
  }

  @media (max-width: 1080px) {
    max-width: 45%;
  }

  @media (max-width: 720px) {
    padding: 10px;
    max-width: 100%;
    flex-direction: row;
    box-shadow: 0 3px 0 1px var(--boxshadow_color);

    background-color: white;
    .container-live-box-preview {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      flex-shrink: 0;
      .logo-container {
        margin-bottom: 0;

        img {
          width: 100%;
        }
        .logo-white {
          display: block;
        }
        .logo {
          display: none;
        }
      }
      .status-video {
        display: none;
      }
    }
    .container-live-box-details {
      padding: 0;
      flex-direction: column;
      justify-content: center;
      height: auto;
      color: var(--primary_text_color);
      background-color: transparent;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      box-shadow: unset;
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      > h5 {
        font-weight: bold;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow: unset;
      }
    }
    .container-live-box-details-info {
      position: relative;
      height: auto;
      bottom: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-grow: 1;
      .status-video-mobile {
        display: flex;
        margin-right: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        box-shadow: unset;
        @media(max-width: 370px) {
          margin: 0 0 1.6rem 0;
        
        }
        p {
          margin-top: 0;
          padding: 0.7rem 1rem;

          color: white;
          font-weight: bold;
          font-size: 1.4rem;
          @media(max-width: 370px) {
            font-size: 8px;
  
          }
        }
      }
      .todays-livestreams {
        background: var(--secondary_color);
      }
      .pre-polls {
        background: var(--prepoll_color);
      }
      .pos-polls {
        background: #ffc400;
      }
    }
  }
}

@media (max-width: 425px) {
  .loading-container-live-box-list {
    margin: 10px 0;
    height: 90px;
    width: 100vw;
  }
  .suspense-screen-home {
    .title-suspense {
      margin: 5vw;
    }
    .lives-list-suspense {
      margin: 5vw;
    }
  }
}
