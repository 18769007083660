@import "src/customer";
.button {
  border: solid;
  border-width: 2px;
  min-width: 180px;
  border-radius: 2.5rem;
  border-color: var(--primary_color);
  padding: 5px 10px;
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
  background-color: var(--primary_color);
  transition: 0.5s;

  &:disabled {
    cursor: not-allowed;
  }
}
.nobackgroung {
  background-color: transparent;
  color: var(--primary_color);
  border: 2px solid var(--primary_color);
}
.button-primary {
  background-color: var(--primary_color);
  border: 2px solid var(--primary_color);
  color: white;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    transform: scale(0.95);
  }
  &:disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: #dedede !important;
    transform: scale(0.90);
    border: none;
  }
}
// .button-primary:hover {
//   background-color: white;
//   border-color: var(--primary_color);
//   color: var(--primary_color);
// }
.button-secondary {
  background-color: white;
  border: 2px solid var(--primary_color);
  color: var(--primary_color);
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    transform: scale(0.95);
  }
  &:disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: #dedede !important;
    transform: scale(0.90);
    border: none;
  }
}
// .button-secondary:hover {
//   background-color: var(--primary_color);
//   border-color: transparent;
//   color: white;
// }
