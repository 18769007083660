@import "src/customer";

div.wrapper {
  display: grid;
  place-items: center;

  width: 100%;
  min-height: 80vh;

  background-color: var(--background_color);
}

div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 50%;
  min-width: 300px;
  height: 30%;

  margin-top: 1rem;

  padding: 1rem;

  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0px 2rem 0px var(--secondary_color);

  text-align: center;

  h1 {
    font-size: 3rem;
  }

  span {
    font-size: 2rem;

    margin-top: 1rem;
  }
}
