@import "src/customer";

.chat-loading {
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
.container-button-chat {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  right: 0;
  width: 74px;
  height: 74px;
  background: var(--primary_color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin: 0px 45px 35px 0px;
  transition: 0.5s;
  cursor: pointer;
  box-shadow: 1px 1px 20px #6161616e;

  span::before {
    font-size: 33px;
    color: white;
  }

  span.icon-close::before {
    font-size: 26px;
  }
}
.container-button-chat:hover {
  background: white;

  span::before {
    color: var(--primary_color);
  }
}
.container-box-chat {
  width: 325px;
  border-radius: 10px;
  background: white;
  z-index: 99999;
  box-shadow: 1px 1px 20px #455b6314;
  overflow: hidden;
  position: fixed;
  will-change: transform;
  bottom: 120px;
  right: 40px;

  > div {
    width: 100%;
    height: 100%;
  }
}
.h-messages {
  height: 340px;
}
.h-chatbox {
  height: 50vh;
}
@media only screen and (max-width: 1000px) {
  .h-chatbox {
    max-height: 50vh;
  }
}

.header-chat {
  width: 325px;
  position: fixed;
  height: 45px;
  display: flex;
  align-items: center;
  background: var(--primary_color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  p {
    color: white;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
.button-header {
  margin-left: 10px;
  color: white;
  cursor: pointer;
  width: 30px;
  height: 45px;
  justify-content: center;
  display: flex;

  span {
    display: flex;
    font-size: 10px;
    align-items: center;
    color: white;
  }
}
.message-header {
  justify-content: center;

  .close-chat {
    position: absolute;
    top: 0;
    right: 10px;
  }
}
.chat-header {
  justify-content: space-between;

  .back-chat {
    margin-left: 10px;
  }
}
.container-img-profile-user {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    height: 100%;
  }
}
.body-chat {
  padding-top: 45px;
  height: 100%;
  overflow: scroll;
}
.body-chat::-webkit-scrollbar {
  display: none;
}
.body-chat .ul-messages {
  margin: 0px 5px;

  li {
    margin: 15px 0px;
    background: white;
    cursor: pointer;
    user-select: none;
  }

  li:hover {
    background: #eeeeee;
    border-radius: 10px;
  }
  .row-conversation-list {
    margin: 0;
    padding: 5px;
    display: flex;
    background: white;
    border-radius: 5px;

    > div:nth-child(1) {
      max-width: 20%;
      justify-content: center;
      display: flex;
      align-items: center;
      margin: 0 10px 0 0px;

      .container-profile-image {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        overflow: hidden;

        img {
          height: 100%;
        }
      }
    }
    > div:nth-child(2) {
      width: 70%;
      padding-right: 10px;

      p {
        margin: 0;
      }
      p:nth-child(1) {
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        height: 20px;
        overflow: hidden;
        position: relative;
      }
      p:nth-child(1)::after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          white 90%
        );
        width: 20px;
        height: 20px;
      }
      p:nth-child(2) {
        font-size: 12px;
      }
      p:nth-child(3) {
        font-size: 10px;
      }
    }
    div:nth-child(3) {
      display: flex;
      align-items: center;

      div {
        span {
          font-size: 10px;
        }
      }
      > div:nth-child(2) {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background: orange;
        width: 20px;
        height: 20px;
        align-self: center;
        border-radius: 51px;
        color: white;

        span {
          text-align: center;
        }
      }
    }
  }
}
.container-box-chat::-webkit-scrollbar {
  display: none;
}
.container-chat-box {
  height: 100%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;

  .header-chat-box {
    width: 100%;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 4px 16px #455b6314;
    max-height: 118px;
    flex-shrink: 0;

    .row {
      margin: 0;

      > div:nth-child(1),
      > div:nth-child(2) {
        margin: auto;
        padding: 0 7px;

        .container-chat-img-ads {
          width: 40px;
          height: 40px;
          max-height: 35px;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;

          img {
            height: 100%;
          }
        }
      }
    }
    h3 {
      font-size: 14px;
      font-weight: 600;
      color: #454f63;
      max-height: 35px;
      overflow: hidden;
      text-align: left;
      position: relative;
    }
    h3::after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        white 90%
      );
      width: 30px;
      height: 20px;
    }
    p {
      font-size: 14px;
    }
    span {
      font-size: 12px;
      color: #78849e;
      margin-left: 5px;
    }
    button {
      width: 100%;
      margin-top: 10px;
    }
    > div:nth-child(2) {
      margin: auto;
      padding: 0 7px;
    }
  }
  .body-chat-box {
    overflow-x: auto;
    flex-shrink: 1;
    flex-grow: 1;

    &::-webkit-scrollbar {
      width: 7px
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2)
    }

    p {
      font-size: 13px;
    }

    .span-hour {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-self: center;
      color: #78849e;

      span {
        font-size: 10px;
      }
    }
    .received-messages {
      padding: 5px 15px;

      li {
        display: inline-flex;
        padding: 12px;
        background-color: #f7f7fa;
        border-radius: 0 10px 10px 10px;

        p {
          font-size: 12px;
        }
      }
    }
    .sent-messages {
      padding: 5px 15px;
      text-align: right;

      li {
        display: inline-flex;
        padding: 12px;
        background-color: var(--primary_color);
        border-radius: 10px 10px 0px 10px;
        color: white;

        p {
          font-size: 12px;
          word-break: break-word;
        }
      }
    }
  }
  #scroll-chat-box::-webkit-scrollbar {
    display: none;
  }
  .footer-chat-box {
    width: 100%;
    height: 50px;
    display: flex;
    flex-shrink: 0;
    padding: 5px 10px;
    background: white;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -4px 16px #455b6314;

    form {
      width: 100%;

      > div {
        margin: 0;
        width: 100%;
        display: flex;

        input {
          width: 85%;
          font-size: 12px;
          padding: 0px 10px;
          border-radius: 20px;
        }
        > div {
          width: 15%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          > button {
            padding: 6px;
            font-size: 18px;
            cursor: pointer;
            color: var(--primary_color);
            transition: 0.2s;
            border-radius: 50px;
            transition: 0.5s;
          }
        }
      }
    }
  }
}
.warn-chat {
  align-items: center;
  display: flex;

  span {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}
.notification-chat {
  width: 25px;
  height: 25px;
  background: var(--secondary_color);
  border-radius: 50px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: white;
  font-weight: 600;
}
.notification-on {
  display: flex;
}
.notification-off {
  display: none;
}
