@import "src/customer";

.login-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);

  .login {
    width: 100%;
    max-width: 560px;
    padding: 3rem;
    background: white;
    margin-left: auto;
    margin-right: auto;
    border-top-right-radius: 4rem;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
    box-shadow: 0px 2rem 0px var(--secondary_color);

    .links {
      li {
        list-style: none;
      }
    }
    .logo {
      text-align: center;
      img {
        max-width: 60%;
        padding: 1rem;
        margin: auto;
      }
    }
    .text {
      margin-top: 2rem;
      margin-bottom: 4rem;
      text-align: center;

      .help {
        margin-top: 25px;
        font-size: 1.6rem;
        font-weight: 400;
        color: #5c5d5d;

        a {
          font-weight: 700;
          color: var(--primary_text_color);

          &:hover {
            text-decoration: underline;
          }
        }
      }

      h1 {
        font-size: 3.2rem;
        font-weight: bold;
        color: var(--primary_text_color);
        font-family: 'Exo 2', sans-serif;
      }
      p {
        margin-top: 1.6rem;
        font-size: 2rem;
        color: var(--primary_text_color);
        font-weight: 600;
      }
      span {
        display: block;
        margin-top: 2.6rem;
        font-size: 1.4rem;
        color: #78849e;
      }
    }
    .email {
      color: var(--secondary_text_color);
    }
  }
}
@media only screen and (max-width: 425px) {
  .login-container {
    .login {
      max-width: 330px;
    }
  }
}
.links {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  li {
    margin-bottom: 1.4rem;

    &:last-child {
      margin-bottom: 0;
    }
    button {
      position: relative;
      display: block;
      width: 100%;
      padding: 1.8rem;
      border-radius: 17px;
      font-size: 1.8rem;
      text-align: center;
      outline: 0;

      i {
        size: 26px;
        position: absolute;
        top: calc(50% - var(--size) / 2);
        left: 30px;
        width: var(--size);
        height: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      div {
        margin: 0 !important;
      }
    }
  }
}