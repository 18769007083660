@import "src/customer";

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 0.8rem;
  border-radius: 10px;
}
.controls {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}
.player__button {
  padding: 8px;
  img {
    height: 15px;
    width: 15px;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  .time {
    color: #111111;
    font-size: 16px;
  }
  .progress {
    flex: 1;
    border-radius: 5px;
    margin: 0 20px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    position: relative;
  }
  .progress__knob {
    position: relative;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: black;
    transition: left 250ms linear;
  }
  .progress__background {
    background: var(--secondary_color);
    position: absolute;
    height: 100%;
    transition: width 250ms linear;
  }
}