@import "src/customer";

header {
  display: grid;
  place-items: center;

  width: 100%;

  padding: 1rem 3vw;

  background-color: #fff;

  border-top-style: solid;
  border-top-width: 7px;
  border-top-color: var(--primary_color);

  div.container-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 68px;
    width: 100%;

    margin-bottom: 0.5rem;

    div.logo {
      width: 140px;

      > img {
        width: 100%;
        height: 100%;
        max-height: 90px;

        object-fit: cover;
      }
    }
  }
}

div.content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  font-size: 1.5rem;

  background: #fff;

  padding: 15px 0;

  > div {
    color: var(--primary_text_color);
  }
}
