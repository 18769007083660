@import "src/customer";

.bottom-bar-container {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 35px 20px 20px;
  background-color: var(--primary_color);
  box-shadow: 0 1px 20px #00000033;
}

.bottom-bar-close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
}

.bottom-bar-content {
  font-size: 14px;
  color: #fff !important;
}

.bottom-bar-content a {
  color: #fff;
}

@media (max-width: 600px) {
  .bottom-bar-container {
    padding: 12px 35px 12px 12px;
  }

  .bottom-bar-close {
    top: 2px;
    right: 2px;
  }
}