.react-select {
  width: 100%;
}

.css-yk16xz-control {
  background-color: #edf2f7 !important;

  border-radius: 8px !important;

  border-style: none !important;
  border-width: 0 !important;
}
