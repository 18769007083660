@import "src/customer";

.label-checkbox {
  font-size: 1.8rem;
  color: var(--secondary_text_color);
  margin: 0;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  padding: 1rem;
  @media only screen and (max-width: 720px) {
    margin-bottom: 1.4rem;
  }
}
.label-text {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 0.6rem;
}
.label-description.recording {
  text-decoration: line-through;
}
.label-description.confirmed {
  p {
    text-decoration: line-through;
    margin-bottom: 0.5rem;
  }
}
.label-description {
  p {
    margin-bottom: -0.4rem;

    a {
      color: var(--primary_color) !important;
    }

    span {
      display: flex;
      align-items: center;

      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  small {
    font-style: italic;
  }
}
input[type="radio"] {
  display: none;

  + .label-text:before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    background: white;
    border: solid;
    border-width: 2px;
    border-color: var(--secondary_text_color);
    border-radius: 50px;
    transition: 0.1s;
  }
  &:checked + .label-text:before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    background: var(--secondary_text_color);
    border: solid;
    border-color: var(--secondary_text_color);
    border-width: 2px;
    box-shadow: 0 0 0 2px white inset;
    border-radius: 53px;
    transition: 0.1s;
  }
}
input[type="checkbox"] {
  display: none;

  + .label-text:before {
    content: "";
    width: 18px;
    height: 18px;
    display: block;
    background: white;
    border: solid;
    border-width: 2px;
    border-color: var(--secondary_text_color);
    border-radius: 5px;
    transition: 0.1s;
  }
  &:checked + .label-text:before {
    content: "\e91b";
    font-family: "halloicons" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 10px;
    text-transform: none;
    color: var(--secondary_text_color);
    text-align: center;
    width: 18px;
    height: 18px;
    position: absolute;
    border: solid;
    border-color: var(--secondary_text_color);
    border-width: 2px;
    box-shadow: 0 0 0 1px white inset;
    border-radius: 5px;
    transition: 0.1s;
  }
}
