@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

:root {
  --lp-primary-color: #004a36;
  --lp-secondary-color: #9a7b37;
  --lp-white: #ffffff;
  --lp-text-light-grey: #e4e4e4;
  --lp-text-dark-grey: #615f5f;
}


.outterContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
}
.outterContainer img {
  max-width: 100%;
}
@media (max-width: 1080px) {
  .outterContainer {
    background-size: contain;
    background-position: unset;
  }
}

.outterContainer .leftCurves img {
  position: absolute;
  height: 110%;
  z-index: 1;
  top: 0;
  left: -180px;
}

.outterContainer .rightCurves img {
  position: absolute;
  height: 110%;
  z-index: 1;
  top: 0;
  right: -100px;
}

.innerContainer.autoHeight {
  height: auto;
}

.innerContainer {
  padding: 3rem 0 0;
  margin: 0 auto;
  width: 90%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
}