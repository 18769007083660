.jss43 {
  display: none !important;
}
.react-draggable {
  z-index: 20 !important;
}
.zoom-icon {
  display: none;
  &:disabled {
    cursor: not-allowed !important;
  }
  @media only screen and (max-width: 720px) {
    display: block;
  }
}
.zoom-button {
  &:disabled {
    cursor: not-allowed;
  }
  @media only screen and (max-width: 720px) {
    display: none;
  }
}
