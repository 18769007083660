@import 'src/customer';

.modal-authentication {
  margin: 3rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    padding: 3rem;
    min-width: 70%;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    color: var(--primary_text_color);
  }

  button {
    margin-top: 20px;
  }

  h3 {
    font-size: 16px;
  }
}