.comments-header {
  flex: 0 0 50px;
  display: flex;
  color: white;
  height: 50px;
  padding: 0 2rem;
  align-items: center;
  background: var(--secondary_color);
  @media only screen and (max-width: 720px) {
    border-radius: 0 20px 0 0;
    height: auto;
  }
  .counter-online-users {
    font-size: 1.7rem;
    margin-left: auto;
  }
  .icon {
    margin-right: 1.4rem;
  }
  > p {
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
  }
}
